.App {
  text-align: center;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  background: linear-gradient(to right, #30cf65, #009933);
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  width: 130px; 
  height: auto;
  margin-left: 10px;
}

.navbar-user {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.logout-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  cursor: pointer;
}


.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20vh; 
}

.options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.download-button,
.date-picker,
.attach-file {
  margin: 0 70px;
}


.icon {
  margin-right: 5px;
  margin-left: 5px;
}


.file-input {
  display: none;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto; 
}

.solicitud{
  text-align: left;
  font-weight: bold;
}

.button-separator {
  flex: 1; /* flex: 1 para ocupar todo el espacio disponible */
}

.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reference-table {
  border-collapse: collapse;
  margin-top: 20px;
  border: 1px solid #ccc;
}

.reference-table th,
.reference-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
  
}

.button-group {
  display: flex;
  margin-top: 10px;
}

.file-action-button {
  padding: 5px 10px;
  background-color: #16cc71;
  cursor: pointer;
}

.reference-table {
  width: calc(100% - 20px);
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.table-container {
  display: flex;
  justify-content: center;
  margin-left: 100px;
  margin-right: 100px;
}


.download-button{
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 10px;
  border-color: #000000;
  background-color: #16cc71;
  color: rgb(1, 1, 1);
  cursor: pointer;
  margin-top: 52px;
}

.file-input-label {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 10px;
  background-color: #eeecec;
  color: rgb(1, 1, 1);
  cursor: pointer;
  
}

.file-action-button{
  margin-left: 10px;
  border-radius: 10px;
}